import { template as template_f28a1e2fd70e47479cf6c88915dc33c7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_f28a1e2fd70e47479cf6c88915dc33c7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
