import { template as template_02778e82333640af8a564ce7aa185d35 } from "@ember/template-compiler";
const FKControlMenuContainer = template_02778e82333640af8a564ce7aa185d35(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
