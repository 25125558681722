import { template as template_361b8f9ce96e4a1cbeb0b762b5820dcf } from "@ember/template-compiler";
const SidebarSectionMessage = template_361b8f9ce96e4a1cbeb0b762b5820dcf(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
